@use "~@ef-global/web-ui/scss/settings/mixins/breakpoints";

.blogSectionWrap {
  background: #FBF9F1;
  @include breakpoints.breakpoint(2200px) {
    background: #FFFFFF;
  }
}

.blogSection {
  grid-column: main;
  padding-top: 64px;

  @include breakpoints.breakpoint(l) {
    padding-top: 128px;
    grid-column: main;
  }
}

.blogButton {
  margin-top: 32px;
  grid-column: main;
}