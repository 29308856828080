@use "~@ef-global/web-ui/scss/settings/mixins/breakpoints";

.outer {
  grid-column: bounds-start / bounds-end;
  background-color: var(--footer-bg);
}

.wrapper {
  background: url('/sign-up-bg.svg') no-repeat center center / cover var(--color-dark-grey);
  display: flex;
  flex-direction: column;
  padding: 48px 24px;
  grid-column: bounds-start / bounds-end;
  margin-top: 64px;

  @include breakpoints.breakpoint(l) {
    margin-top: 128px;
    grid-column: main-start/main-end;
  }

  .formCopy {
    max-width: 75%;
  }

  @include breakpoints.breakpoint(m) {
    grid-column: main-start / main-end;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-radius: 8px;
    padding: 56px 64px;

    .formCopy {
      max-width: none;
      flex: 0 0 40%;
    }
    .formForm {
      flex: 0 0 50%;
    }
  }
}

.formInput {
  display: flex;
  @include breakpoints.breakpoint(s only) {
    margin-top: 24px;
  }
}

.formTextInput {
  flex: 1 0;

  input {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    box-shadow: none;
    border: none;
    background-color: white;

    &:focus {
      border: none;
      box-shadow: none;
    }
  }
}

.formButton {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  padding: 12px 24px;
  line-height: 24px;
  font-size: 14px;
  background-color: white;
  cursor: pointer;
  border-left: 1px solid var(--color-divider);
}

.errorMessage {
  color: white;
  margin-top: 8px;
  display: block;
  font-size: 12px;
  position: absolute;
}